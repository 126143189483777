/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Theme: https://www.creative-tim.com/product/material-dashboard-pro-react

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import React, { lazy } from "react";
// import smallSphere from "assets/images/sptech-logos/small-sphere.webp";
// Profile page
const Profile = lazy(() => import("views/pages/profile"));

// mfr01
const MFR01DataVisualization = lazy(() => import("views/clients/mfr01/data-visualization"));
const MFR01DataEntry = lazy(() => import("views/clients/mfr01/data-entry/mobileView"));
const MFR01BulkDataEntry = lazy(() => import("views/clients/mfr01/bulk-data-entry"));
const MFR01CustomerInquiryDataManager = lazy(
  () => import("views/clients/mfr01/customer-inquiry-data-manager")
);
const MFR01Settings = lazy(() => import("views/clients/mfr01/settings"));

// rwa01
const RWA01DataCentre = lazy(() => import("views/clients/rwa01/data-centre"));
const RWA01Dashboard = lazy(() => import("views/clients/rwa01/dashboard"));
const RWA01IVRManager = lazy(() => import("views/clients/rwa01/ivr-manager"));
const RWA01Settings = lazy(() => import("views/clients/rwa01/settings"));

// qex01
const QEX01DataCentre = lazy(() => import("views/clients/qex01/data-centre"));
const QEX01UserExtensionsManager = lazy(() => import("views/clients/qex01/user-extension-manager"));
const QEX01Settings = lazy(() => import("views/clients/qex01/settings"));

// spt01
const SPT01SubscriptionClients = lazy(() => import("views/clients/spt01/subscriptions/clients"));
const SPT01SubscriptionLicenses = lazy(() => import("views/clients/spt01/subscriptions/licenses"));
const SPT01SubscriptionManager = lazy(
  () => import("views/clients/spt01/subscriptions/subscription-manager")
);
const SPT01SubscriptionVendors = lazy(() => import("views/clients/spt01/subscriptions/vendors"));
const SPT01Settings = lazy(() => import("views/clients/spt01/settings"));
// const SPT01ProductCatalog = lazy(() => import("views/clients/spt01/Inventory/ProductCatalog"));
const SPT01Dashboard = lazy(() => import("views/clients/spt01/Dashboard"));
// const SPT01ClientContactBook = lazy(() => import("views/clients/spt01/clientContactBook"));

// tools
// const NableAssetManager = lazy(() => import("views/tools/nAbleFileTranform"));
// const JsonToExcel = lazy(() => import("views/tools/JsonToExcel"));
// const ExcelKeywordProcessor = lazy(() => import("views/tools/ExcelKeywordProcessor"));
// const ImagesToDoc = lazy(() => import("views/tools/ImagesToDoc"));

// admin
const GlobalSettings = lazy(() => import("views/admin/globalSettings"));
const UserManagement = lazy(() => import("views/admin/userManagement"));
const ClientSettings = lazy(() => import("views/admin/clientSettings"));

const routes = [
  { type: "divider", key: "divider-0" },
  // {
  //   type: "collapse",
  //   name: "Commander",
  //   key: "commander",
  //   noCollapse: true,
  //   icon: <img src={smallSphere} height={"24px"} width={"24px"} />,
  //   href: "https://commander.sptech.com.au:8085/home",
  // },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: "groups_rounded",
    collapse: [
      {
        type: "collapse",
        name: "SPT01",
        key: "spt01",
        icon: "home_work_rounded",
        collapse: [
          {
            type: "collapse",
            icon: "inventory_2_rounded",
            name: "Dashboard",
            key: "dashboard",
            permissions: [
              "read:spt01_inventory",
              "read:clients",
              "read:client_contact_book",
              "read:images_to_doc",
            ],
            route: "/clients/spt01/dashboard",
            component: <SPT01Dashboard />,
          },
          {
            type: "collapse",
            icon: "workspace_premium_rounded",
            name: "License Manager",
            key: "license-manager",
            collapse: [
              {
                type: "collapse",
                icon: "category",
                name: "Vendors",
                key: "vendors",
                permissions: ["read:spt01_subscription_vendor"],
                route: "/clients/spt01/license-manager/vendors",
                component: <SPT01SubscriptionVendors />,
              },
              {
                type: "collapse",
                icon: "group_rounded",
                name: "Clients",
                key: "clients",
                permissions: ["read:spt01_subscription_client"],
                route: "/clients/spt01/license-manager/clients",
                component: <SPT01SubscriptionClients />,
              },
              {
                type: "collapse",
                icon: "table_rows_rounded",
                name: "Licenses",
                key: "licenses",
                permissions: ["read:spt01_subscription_license"],
                route: "/clients/spt01/license-manager/licenses",
                component: <SPT01SubscriptionLicenses />,
              },
              {
                type: "collapse",
                icon: "table_chart_rounded",
                name: "Subscriptions",
                key: "subscriptions",
                permissions: ["read:spt01_subscription_history"],
                route: "/clients/spt01/license-manager/subscriptions",
                component: <SPT01SubscriptionManager />,
              },
            ],
          },
          // {
          //   type: "collapse",
          //   icon: "workspace_premium_rounded",
          //   name: "Inventory",
          //   key: "inventory",
          //   collapse: [
          //     {
          //       type: "collapse",
          //       icon: "inventory_2_rounded",
          //       name: "Dashboard",
          //       key: "dashboard",
          //       permissions: ["read:spt01_inventory"],
          //       route: "/clients/spt01/inventory/dashboard",
          //       component: <SPT01InventoryDashboard />,
          //     },
          //     // {
          //     //   type: "collapse",
          //     //   icon: "inventory_2_rounded",
          //     //   name: "Stock Manager",
          //     //   key: "stock-manager",
          //     //   permissions: ["read:spt01_inventory"],
          //     //   route: "/clients/spt01/inventory/stock-manager",
          //     //   component: <SPT01Inventory />,
          //     // },
          //     // {
          //     //   type: "collapse",
          //     //   icon: "document_scanner",
          //     //   name: "Scan In",
          //     //   key: "scan-in",
          //     //   permissions: ["read:spt01_inventory"],
          //     //   route: "/clients/spt01/inventory/scan-in",
          //     //   component: <SPT01BulkScanIn />,
          //     // },
          //     // {
          //     //   type: "collapse",
          //     //   icon: "document_scanner",
          //     //   name: "Scan Out",
          //     //   key: "scan-out",
          //     //   permissions: ["read:spt01_inventory"],
          //     //   route: "/clients/spt01/inventory/scan-out",
          //     //   component: <SPT01BulkScanOut />,
          //     // },
          //     // {
          //     //   type: "collapse",
          //     //   icon: "update",
          //     //   name: "Assign Stock",
          //     //   key: "assign-stock",
          //     //   permissions: ["read:spt01_inventory"],
          //     //   route: "/clients/spt01/inventory/assign-stock",
          //     //   component: <SPT01BulkAssign />,
          //     // },
          //     // {
          //     //   type: "collapse",
          //     //   icon: "category",
          //     //   name: "Product Catalog",
          //     //   key: "product-catalog",
          //     //   permissions: ["read:spt01_product_catalog"],
          //     //   route: "/clients/spt01/inventory/product-catalog",
          //     //   component: <SPT01ProductCatalog />,
          //     // },
          //   ],
          // },
          // {
          //   type: "collapse",
          //   icon: "workspace_premium_rounded",
          //   name: "Tools",
          //   key: "tools",
          //   collapse: [
          //     {
          //       type: "collapse",
          //       icon: "transform_rounded",
          //       name: "Images To Doc",
          //       key: "images-to-doc",
          //       permissions: ["read:images_to_doc"],
          //       route: "/clients/spt01/tools/images-to-doc",
          //       component: <ImagesToDoc />,
          //     },
          //     {
          //       type: "collapse",
          //       icon: "description_rounded",
          //       name: "N-able Asset Manager",
          //       key: "n-able-asset-manager",
          //       permissions: ["read:n_able_file_transform_tool"],
          //       route: "/clients/spt01/tools/n-able-asset-manager",
          //       component: <NableAssetManager />,
          //     },
          //     {
          //       type: "collapse",
          //       icon: "description_rounded",
          //       name: "Excel Report",
          //       key: "excel-report",
          //       permissions: ["read:excel_keyword_processor_tool"],
          //       route: "/clients/spt01/tools/excel-report",
          //       component: <ExcelKeywordProcessor />,
          //     },
          //     {
          //       type: "collapse",
          //       icon: "description_rounded",
          //       name: "Json To Excel",
          //       key: "json-to-excel",
          //       permissions: ["read:json_to_excel_tool"],
          //       route: "/clients/spt01/tools/json-to-excel",
          //       component: <JsonToExcel />,
          //     },
          //   ],
          // },
          // {
          //   type: "collapse",
          //   icon: "import_contacts_rounded",
          //   name: "Client Contact Book",
          //   key: "client_contact_book",
          //   permissions: ["read:client_contact_book"],
          //   route: "/clients/spt01/client-contact-book",
          //   component: <SPT01ClientContactBook />,
          // },
          {
            type: "collapse",
            icon: "settings_suggest_rounded",
            name: "Settings",
            key: "settings",
            permissions: ["read:spt01_client"],
            route: "/clients/spt01/settings",
            component: <SPT01Settings />,
          },
        ],
      },
      {
        icon: "home_work_rounded",
        type: "collapse",
        name: "MFR01",
        key: "mfr01",
        collapse: [
          {
            type: "collapse",
            icon: "workspace_premium_rounded",
            name: "Customer Inquiries",
            key: "customer-inquiries",
            collapse: [
              {
                type: "collapse",
                icon: "dashboard",
                name: "Dashboard",
                key: "dashboard",
                permissions: ["read:mfr01_dashboard"],
                route: "/clients/mfr01/customer-inquiries/dashboard",
                component: <MFR01DataVisualization />,
              },
              {
                type: "collapse",
                icon: "article_rounded",
                name: "Data Entry",
                key: "data-entry-form",
                permissions: ["create:mfr01_customer_inquiries"],
                route: "/clients/mfr01/customer-inquiries/data-entry-form",
                component: <MFR01DataEntry />,
              },
              {
                type: "collapse",
                icon: "upload_file_rounded",
                name: "Data Centre",
                key: "data-centre",
                permissions: [
                  "read:mfr01_google_ads_latest_available_data_date",
                  "create:mfr01_customer_inquiries_from_file",
                  "create:mfr01_google_ads_data_from_file",
                ],
                route: "/clients/mfr01/customer-inquiries/data-centre",
                component: <MFR01BulkDataEntry />,
              },
              {
                type: "collapse",
                icon: "table_chart_rounded",
                name: "Inquiry Manager",
                key: "inquiry-manager",
                permissions: ["read:mfr01_customer_inquiries"],
                route: "/clients/mfr01/customer-inquiries/inquiry-manager",
                component: <MFR01CustomerInquiryDataManager />,
              },
            ],
          },
          {
            type: "collapse",
            icon: "settings_suggest_rounded",
            name: "Settings",
            key: "settings",
            permissions: ["read:mfr01_client"],
            route: "/clients/mfr01/settings",
            component: <MFR01Settings />,
          },
        ],
      },
      {
        type: "collapse",
        name: "RWA01",
        key: "rwa01",
        icon: "home_work_rounded",
        collapse: [
          {
            type: "collapse",
            icon: "workspace_premium_rounded",
            name: "IVR Call Manager",
            key: "ivr-call-manager",
            collapse: [
              {
                type: "collapse",
                icon: "dashboard",
                name: "Dashboard",
                key: "dashboard",
                permissions: ["read:rwav01_inbound_call_dashboard"],
                route: "/clients/rwa01/ivr-call-manager/dashboard",
                component: <RWA01Dashboard />,
              },
              {
                type: "collapse",
                icon: "import_export_rounded",
                name: "Data Warehouse",
                key: "data-warehouse",
                permissions: [
                  "read:rwav01_ivr_excel_reports",
                  "read:rwav01_inbound_call_statistics",
                  "read:rwav01_outbound_call_statistics",
                  "read:rwav01_inbound_call_summary",
                  "read:rwav01_outbound_call_summary",
                  "read:rwav01_inbound_call_graphical_report",
                  "read:rwav01_outbound_call_graphical_report",
                ],
                route: "/clients/rwa01/ivr-call-manager/data-warehouse",
                component: <RWA01DataCentre />,
              },
              {
                type: "collapse",
                icon: "table_chart_rounded",
                name: "IVR Builder",
                key: "ivr-builder",
                permissions: ["read:rwav01_ivr_ring_groups"],
                route: "/clients/rwa01/ivr-call-manager/ivr-builder",
                component: <RWA01IVRManager />,
              },
            ],
          },
          {
            type: "collapse",
            icon: "settings_suggest_rounded",
            name: "Settings",
            key: "settings",
            permissions: ["read:rwav01_client"],
            route: "/clients/rwa01/settings",
            component: <RWA01Settings />,
          },
        ],
      },
      {
        type: "collapse",
        name: "QEX01",
        key: "qex01",
        icon: "home_work_rounded",
        collapse: [
          {
            type: "collapse",
            icon: "workspace_premium_rounded",
            name: "Calls Manager",
            key: "calls-manager",
            collapse: [
              {
                type: "collapse",
                icon: "import_export_rounded",
                name: "Data Centre",
                key: "data-centre",
                permissions: [
                  "read:qex01_ivr_calls_latest_available_data_date",
                  "read:qex01_ivr_calls_excel_report",
                ],
                route: "/clients/qex01/calls-manager/data-centre",
                component: <QEX01DataCentre />,
              },
              {
                type: "collapse",
                icon: "table_chart_rounded",
                name: "User Extensions",
                key: "user-extensions",
                permissions: ["read:qex01_ivr_user_extensions"],
                route: "/clients/qex01/calls-manager/user-extensions",
                component: <QEX01UserExtensionsManager />,
              },
            ],
          },
          {
            type: "collapse",
            icon: "settings_suggest_rounded",
            name: "Settings",
            key: "settings",
            permissions: ["read:qex01_client"],
            route: "/clients/qex01/settings",
            component: <QEX01Settings />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Administration",
    key: "administration",
    icon: "admin_panel_settings_rounded",
    collapse: [
      {
        type: "collapse",
        icon: "settings_rounded",
        name: "Global Settings",
        key: "global-settings",
        permissions: ["read:smtp_email_provider"],
        route: "/administration/global-settings",
        component: <GlobalSettings />,
      },
      {
        type: "collapse",
        icon: "settings_rounded",
        name: "Client Settings",
        key: "client-settings",
        permissions: ["read:clients"],
        route: "/administration/client-settings",
        component: <ClientSettings />,
      },
      {
        type: "collapse",
        icon: "manage_accounts_rounded",
        name: "Users",
        key: "users",
        permissions: ["read:users"],
        route: "/administration/users",
        component: <UserManagement />,
      },
    ],
  },
];

const pageRoutes = [
  {
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: "person",
    component: <Profile />,
  },
];

export { pageRoutes };
export default routes;
